import { withDependencies } from '@wix/thunderbolt-ioc'
import { SdkHandlersProvider } from '@wix/thunderbolt-symbols'
import { AnimationData, IAnimations, WixCodeAnimationsHandlers } from '../types'
import { Animations } from '../symbols'

const CLEAR_PROPS = 'clip,clipPath,webkitClipPath,opacity,transform'
export const wixCodeHandlersProvider = withDependencies(
	[Animations],
	(animations: IAnimations): SdkHandlersProvider<WixCodeAnimationsHandlers> => {
		return {
			getSdkHandlers() {
				return {
					async runAnimation(animationData: AnimationData): Promise<void> {
						let resolvePromise: () => void
						const animationCompletePromise = new Promise<void>((resolve) => {
							resolvePromise = resolve
						})
						const animatorManager = await animations.getInstance()

						const baseClearData = {
							name: 'BaseClear',
							targetId: animationData.targetId,
							duration: 0,
							delay: 0,
							params: {
								props: CLEAR_PROPS,
								immediateRender: false,
							},
						}
						animatorManager.runSequence(
							[
								{ type: 'Animation', data: animationData },
								{ type: 'Animation', data: baseClearData },
							],
							{
								onComplete: () => resolvePromise(),
								// TODO maybe onInterrupt and onReverseComplete not needed for platform handler?
								onInterrupt: () => resolvePromise(),
							}
						)
						return animationCompletePromise
					},
				}
			},
		}
	}
)
